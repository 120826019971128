import React from "react";

export default function Authenticator(){
    return(
        <>
        <div className='login-auth-section'>
      <img className='logo-img' src="asstes/img/logo.png" alt="" />
      <div className="login-block" id="authotp">
         <div className="title-text mb-3">
            <div className="title login">
            Verify your Identity
            </div>
         </div>
         <div className="form-container">
            
            <div className="form-inner mt-3">          
                <p className="text-center">
                Enter the 6-digit verification code generated 
                <br />by the <b>Google Authenticator App</b>
                </p>
               <form action="#" className="login mt-4">
                  <div className='row'>
                  <div className="col-12 col-md-12">
                     <div className='field'>
                     <input type="number"  id="otp" placeholder="" required />
                     <input type="number" id="otp" placeholder="" required />
                     <input type="number" id="otp" placeholder="" required />
                     <input type="number" id="otp" placeholder="" required />
                     <input type="number" id="otp" placeholder="" required />
                     <input type="number" id="otp" placeholder="" required />
                     </div>
                  </div>
                  <div className='col-12 col-md-12 mt-4'>
                  <button type="submit" className="btn btn-primary" style={{width: "100%"}}>Verify and Save</button>
                  </div>
                  </div>
               </form>
            </div>
         </div>
      </div>
   </div>
        </>
    )
}