import React from "react";
export default function Sidebar(){
return(
<>
<aside className="sidebar position-fixed top-0 left-0 overflow-auto h-100 float-left" id="show-side-navigation1">
   {/* <i className="uil-bars close-aside d-md-none d-lg-none" data-close="show-side-navigation1"></i> */}
   <div className="sidebar-header d-flex justify-content-between align-items-center px-3 py-4">
      <img
         className="rounded-pill img-fluid"
         width="50%"
         src="asstes/img/logo.png"
         alt="" />
      <div className="ms-0">
         <h5 className="fs-6 mb-0">
            <a className="text-decoration-none" href="#">
            <i className="fa fa-bell"></i>
            </a>
         </h5>
         {/* <p className="mt-1 mb-0">Lorem ipsum dolor sit amet consectetur.</p> */}
      </div>
   </div>
   {/* <div className="search position-relative text-center px-4 py-3 mt-2">
      <input type="text" className="form-control w-100 border-0 bg-transparent" placeholder="Search here" />
      <i className="fa fa-search position-absolute d-block fs-6"></i>
   </div> */}
   <ul className="categories list-unstyled pt-1 pb-2">
      <li className="has-dropdown">
         <i className="uil-estate fa-fw"></i><a href="#"> Dashboard</a>
         <ul className="sidebar-dropdown list-unstyled">
            <li><a href="#">Lorem ipsum</a></li>
            <li><a href="#">ipsum dolor</a></li>
            <li><a href="#">dolor ipsum</a></li>
            <li><a href="#">amet consectetur</a></li>
            <li><a href="#">ipsum dolor sit</a></li>
         </ul>
      </li>
      {/* <li className="">
         <i className="uil-folder"></i><a href="#"> File manager</a>
      </li> */}
      <li className="has-dropdown">
         <i className="uil-calendar-alt"></i><a href="#"> Tasks</a>
         <ul className="sidebar-dropdown list-unstyled">
            <li><a href="#">Lorem ipsum</a></li>
            <li><a href="#">ipsum dolor</a></li>
            <li><a href="#">dolor ipsum</a></li>
            <li><a href="#">amet consectetur</a></li>
            <li><a href="#">ipsum dolor sit</a></li>
         </ul>
      </li>
      <li className="has-dropdown">
         <i className="uil-envelope-download fa-fw"></i><a href="#"> Mailbox</a>
         <ul className="sidebar-dropdown list-unstyled">
            <li><a href="#">Lorem ipsum</a></li>
            <li><a href="#">ipsum dolor</a></li>
            <li><a href="#">dolor ipsum</a></li>
            <li><a href="#">amet consectetur</a></li>
            <li><a href="#">ipsum dolor sit</a></li>
         </ul>
      </li>
      <li className="has-dropdown">
         <i className="uil-shopping-cart-alt"></i><a href="#"> Ecommerce</a>
         <ul className="sidebar-dropdown list-unstyled">
            <li><a href="#">Lorem ipsum</a></li>
            <li><a href="#">ipsum dolor</a></li>
            <li><a href="#">dolor ipsum</a></li>
            <li><a href="#">amet consectetur</a></li>
            <li><a href="#">ipsum dolor sit</a></li>
         </ul>
      </li>
      <li className="has-dropdown">
         <i className="uil-bag"></i><a href="#"> Projects</a>
         <ul className="sidebar-dropdown list-unstyled">
            <li><a href="#">Lorem ipsum</a></li>
            <li><a href="#">ipsum dolor</a></li>
            <li><a href="#">dolor ipsum</a></li>
            <li><a href="#">amet consectetur</a></li>
            <li><a href="#">ipsum dolor sit</a></li>
         </ul>
      </li>
      <li className="">
         <i className="uil-setting"></i><a href="#"> Settings</a>
         <ul className="sidebar-dropdown list-unstyled">
            <li><a href="#">Lorem ipsum</a></li>
            <li><a href="#">ipsum dolor</a></li>
            <li><a href="#">dolor ipsum</a></li>
            <li><a href="#">amet consectetur</a></li>
            <li><a href="#">ipsum dolor sit</a></li>
         </ul>
      </li>
      <li className="has-dropdown">
         <i className="uil-chart-pie-alt"></i><a href="#"> Components</a>
         <ul className="sidebar-dropdown list-unstyled">
            <li><a href="#">Lorem ipsum</a></li>
            <li><a href="#">ipsum dolor</a></li>
            <li><a href="#">dolor ipsum</a></li>
            <li><a href="#">amet consectetur</a></li>
            <li><a href="#">ipsum dolor sit</a></li>
         </ul>
      </li>
      <li className="has-dropdown">
         <i className="uil-panel-add"></i><a href="#"> Charts</a>
         <ul className="sidebar-dropdown list-unstyled">
            <li><a href="#">Lorem ipsum</a></li>
            <li><a href="#">ipsum dolor</a></li>
            <li><a href="#">dolor ipsum</a></li>
            <li><a href="#">amet consectetur</a></li>
            <li><a href="#">ipsum dolor sit</a></li>
         </ul>
      </li>
      <li className="">
         <i className="uil-map-marker"></i><a href="#"> Maps</a>
      </li>
   </ul>
   <div className="sb-sidenav-footer">
      <i className="fa fa-cog" aria-hidden="true"></i>
      <i className="fa fa-question-circle" aria-hidden="true"></i>
      <i className="fa fa-sign-out" aria-hidden="true"></i>
   </div>
</aside>
</>
)
}