import React from "react";
import Sidebar from "./Sidebar"
import './Custom'
export default function Task(){
return(
<>
<Sidebar />
<section id="wrapper">
   <div className="p-4">
      <div className="welcome">
         <div className="content rounded-3 pt-3 pb-3 d-flex justify-content-between align-items-center">
            <div className="content rounded-3 pt-3 pb-3 d-flex justify-content-start align-items-flex-start" id="topmenuoption">
               <a className="menu-opens" href="#">
               <i data-show="show-side-navigation1" className="fa fa-chevron-left show-side-btn"></i>
               </a>
               <div className="page-title">
                  <h1 className="fs-2">Tasks</h1>
                  <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam diam dolor.</p>
               </div>
            </div>
            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addpopup-form">
            <i className="fa fa-plus"></i>
            Add New
            </button>
         </div>
      </div>
      <section className="statistics mt-0">
         <div className="row">
            <div className="col-lg-12">
               <div className="box d-flex align-items-center mb-4 mb-lg-0 p-3" style={{flexDirection: "column"}}>
                  <div className="table-topsection">
                     <div className="left-bar">
                        <input className="input checkbox" type="checkbox" />
                        <select id="sevrity" className="form-select">
                           <option selected>Select Bundle action</option>
                           <option>Industry</option>
                        </select>
                     </div>
                     <div className="right-bar">
                        <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                        <img src="asstes/img/setting.png" />
                        <img src="asstes/img/download.png" />
                     </div>
                  </div>
                  <div className="table-responsive">
                     <table className="table">
                     <thead>
                        <tr>
                           <th scope="col">task</th>
                           <th scope="col">policy</th>
                           <th scope="col">status</th>
                           <th scope="col">severity</th>
                           <th scope="col">due date</th>
                           <th scope="col">plan</th>
                           <th scope="col">imp. score</th>
                           <th scope="col">
                              <div className="action-block">
                              actions
                              <i className="fa fa-plus"></i>
                              </div>
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td className="checktask" scope="row">
                           <input className="input checkbox" type="checkbox" />
                           Task Name
                           </td>
                           <td>
                           On-Premises <br />Network
                           </td>
                           <td>
                              <label className="status">Fullfiled</label>
                           </td>
                           <td>
                              <label className="severity">Critical</label>
                           </td>
                           <td>
                           None
                           </td>
                           <td>
                           None
                           </td>
                           <td>
                           0.50%
                           </td>
                           <td>
                           <a>
                              <img src="asstes/img/view.png" />
                           </a>
                           <a>
                              <img src="asstes/img/edit.png" />
                           </a>
                           <a>
                              <img src="asstes/img/delete.png" />
                           </a>
                           </td>
                        </tr>
                        <tr>
                           <td className="checktask" scope="row">
                           <input className="input checkbox" type="checkbox" />
                           Task Name
                           </td>
                           <td>
                           On-Premises <br />Network
                           </td>
                           <td>
                              <label className="status">Fullfiled</label>
                           </td>
                           <td>
                              <label className="severity">Critical</label>
                           </td>
                           <td>
                           None
                           </td>
                           <td>
                           None
                           </td>
                           <td>
                           0.50%
                           </td>
                           <td>
                           <a>
                              <img src="asstes/img/view.png" />
                           </a>
                           <a>
                              <img src="asstes/img/edit.png" />
                           </a>
                           <a>
                              <img src="asstes/img/delete.png" />
                           </a>
                           </td>
                        </tr>
                        <tr>
                           <td className="checktask" scope="row">
                           <input className="input checkbox" type="checkbox" />
                           Task Name
                           </td>
                           <td>
                           On-Premises <br />Network
                           </td>
                           <td>
                              <label className="status">Fullfiled</label>
                           </td>
                           <td>
                              <label className="severity">Critical</label>
                           </td>
                           <td>
                           None
                           </td>
                           <td>
                           None
                           </td>
                           <td>
                           0.50%
                           </td>
                           <td>
                           <a>
                              <img src="asstes/img/view.png" />
                           </a>
                           <a>
                              <img src="asstes/img/edit.png" />
                           </a>
                           <a>
                              <img src="asstes/img/delete.png" />
                           </a>
                           </td>
                        </tr>
                        <tr>
                           <td className="checktask" scope="row">
                           <input className="input checkbox" type="checkbox" />
                           Task Name
                           </td>
                           <td>
                           On-Premises <br />Network
                           </td>
                           <td>
                              <label className="status">Fullfiled</label>
                           </td>
                           <td>
                              <label className="severity">Critical</label>
                           </td>
                           <td>
                           None
                           </td>
                           <td>
                           None
                           </td>
                           <td>
                           0.50%
                           </td>
                           <td>
                           <a>
                              <img src="asstes/img/view.png" />
                           </a>
                           <a>
                              <img src="asstes/img/edit.png" />
                           </a>
                           <a>
                              <img src="asstes/img/delete.png" />
                           </a>
                           </td>
                        </tr>
                        <tr>
                           <td className="checktask" scope="row">
                           <input className="input checkbox" type="checkbox" />
                           Task Name
                           </td>
                           <td>
                           On-Premises <br />Network
                           </td>
                           <td>
                              <label className="status">Fullfiled</label>
                           </td>
                           <td>
                              <label className="severity">Critical</label>
                           </td>
                           <td>
                           None
                           </td>
                           <td>
                           None
                           </td>
                           <td>
                           0.50%
                           </td>
                           <td>
                           <a>
                              <img src="asstes/img/view.png" />
                           </a>
                           <a>
                              <img src="asstes/img/edit.png" />
                           </a>
                           <a>
                              <img src="asstes/img/delete.png" />
                           </a>
                           </td>
                        </tr>
                     </tbody>
                     </table>
                     <div className="table-footer">
                        <div className="footerdetails">
                           <div className="left-row">
                              <p>Rows per page</p>
                              <select class="form-select" id="exampleFormControlSelect1">
                                 <option>10</option>
                                 <option>20</option>
                                 <option>30</option>
                                 <option>40</option>
                                 <option>50</option>
                              </select>
                           </div>
                           <div className="right-row">
                              <p>1-10 of 100</p>
                              <i className="fa fa-angle-left" style={{marginLeft: ".5rem"}}></i>
                              <i className="fa fa-angle-right"></i>
                           </div>
                        </div>
                     </div>
                     </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</section>
{/* <!-- Add Client --> */}
<div className="modal fade" id="addpopup-form" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
   <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
         <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">Add Client</h5>
            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" style={{"fontWeight":"100","color":"var(--dk-gray-500)"}}>&times;</span>
            </button>
         </div>
         <form action="#" className="login">
            <div className="modal-body">
               <div className="form-inner">
                  <div className="form-row mb-3">
                     <div className="col-12 form-group col-md-12">
                        <label for="policy">Policy</label>
                        <input type="text" className="form-control" id="policy" placeholder="Name" required />
                     </div>
                  </div>
                  <div className="form-row mb-3">
                     <div className="col-12 form-group col-md-6">
                        <label for="status">Status</label>
                        <select id="status" className="form-select">
                           <option selected>Select</option>
                           <option>Active</option>
                        </select>
                     </div>
                     <div className="col-12 form-group col-md-6">
                        <label for="sevrity">Sevrity</label>
                        <select id="sevrity" className="form-select">
                           <option selected>Select</option>
                           <option>Industry</option>
                        </select>
                     </div>
                  </div>
                  <div className="form-row mb-3">
                     <div className="col-12 form-group col-md-12">
                        <label for="date">Due Date</label>
                        <input type="date" className="form-control" id="date" placeholder="DUE DATE" required />
                     </div>
                  </div>
                  <div className="form-row mb-3">
                     <div className="col-12 form-group col-md-12">
                        <label for="plan">Plan</label>
                        <input type="text" className="form-control" id="plan" placeholder="Plan" required />
                     </div>
                  </div>
                  <div className="form-row mb-3">
                     <div className="col-12 form-group col-md-12">
                        <label for="Score">IMP Score</label>
                        <input type="number" className="form-control" id="Score" placeholder="IMP Score" required />
                     </div>
                  </div>
               </div>
            </div>
            <div className="modal-footer">
               <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" style={{"background":"unset","color":"var(--orange)","fontSize":"medium"}}>
               Cancel
               </button>
               <button type="button" className="btn btn-primary">Create</button>
            </div>
         </form>
      </div>
   </div>
</div>
</>
)
}