import $ from "jquery";

// Helper functions
function find(el, selector) {
  return el.querySelector(selector) || null;
}

function siblings(el) {
  return Array.from(el.parentNode.children).filter(sibling => sibling !== el);
}

// jQuery objects
const showAsideBtn = $('.show-side-btn');
const sidebar = $('.sidebar');
const wrapper = $('#wrapper');

$(document).ready(function() {
  // Event handling for showing/hiding the sidebar
  $('.show-side-btn').on('click', function() {
    // Get the ID of the sidebar to show/hide from data attribute
    const sidebarId = $(this).data('show');
    
    // Ensure that sidebarId is defined and not empty
    if (sidebarId) {
      const sidebar = $(`#${sidebarId}`);
      
      // Toggle the sidebar visibility and wrapper width
      sidebar.toggleClass('show-sidebar');
      $('#wrapper').toggleClass('fullwidth');
    }
  });
});


// Event handling with jQuery
$(document).on('click', function(event) {

  // Handle sidebar dropdowns
  if ($(event.target).closest('.sidebar .categories').length) {
    event.preventDefault();
    const item = $(event.target).closest('.has-dropdown')[0];
    if (item) {
      $(item).toggleClass('opened');
      siblings(item).forEach(sibling => $(sibling).removeClass('opened'));

      const toOpen = find(item, '.sidebar-dropdown');
      if (toOpen) {
        $(toOpen).toggleClass('active');
      }

      siblings(item).forEach(sibling => {
        const toClose = find(sibling, '.sidebar-dropdown');
        if (toClose) {
          $(toClose).removeClass('active');
        }
      });
    }
  }

  // Handle closing the sidebar
  if ($(event.target).hasClass('sidebar') && $(event.target).hasClass('close-aside')) {
    $(`#${event.target.dataset.close}`).addClass('show-sidebar');
    wrapper.removeClass('margin');
  }
});

// Window resize handling
$(window).on('resize', function() {
  if (window.innerWidth < 767) {
    sidebar.addClass('show-sidebar');
  } else {
    sidebar.removeClass('show-sidebar');
  }
});

// Initial call to handle page load
$(window).trigger('resize');
