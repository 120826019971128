// src/App.js
import React from "react";
import { BrowserRouter } from "react-router-dom";
import RouteConfig from "./route";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <RouteConfig />
      </div>
    </BrowserRouter>
  );
}

export default App;
