// src/route.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./Components/Login";
import LoginAuth from "./Components/Loginauth";
import Authenticate from "./Components/Authenticate";
import Task from "./Components/Task";
import Scans from "./Components/Scans";

const RouteConfig = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/auth" element={<LoginAuth />} />
      <Route path="/authenticate" element={<Authenticate />} />
      <Route path="/task" element={<Task />} />
      <Route path="/scans" element={<Scans />} />
    </Routes>
  );
};

export default RouteConfig;
