import React from "react";
export default function Login() {
  return (
    <>
      <div className="login-section">
        <img className="logo-img" src="asstes/img/logo.png" alt="" />
        <div className="login-block">
          <div className="title-text">
            <div className="title login">Login</div>
          </div>
          <div className="form-container">
            <div className="slide-controls">
              <a href="#" className="social-icons">
                <i className="fa fa-google" aria-hidden="true"></i>
              </a>
              <a href="#" className="social-icons">
                <i className="fa fa-github" aria-hidden="true"></i>
              </a>
              <a href="#" className="social-icons">
                <i className="fa fa-windows" aria-hidden="true"></i>
              </a>
            </div>
            <div className="moreinfo mt-4">
              <p>or use your email to login</p>
            </div>
            <div className="form-inner">
              <form action="#" className="login">
                <div className="field">
                  <label for="email">Your email</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Email Address"
                    required
                  />
                </div>
                <div className="field">
                  <label for="password">Password</label>
                  <input
                    type="password"
                    id="password"
                    placeholder="Password"
                    required
                  />
                </div>
                <div className="field mt-4">
                  <button type="submit" className="btn btn-primary">
                    Login
                  </button>
                </div>
                <div className="pass-link text-center mt-4">
                  <a href="#">Forgot password?</a>
                </div>
                <div className="signup-link mt-5">
                  New to myVCISO?{" "}
                  <a className="ml-2" href="#">
                    Register
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
