import React from "react";

export default function Loginauth(){
    return(
        <>
        <div className='login-auth-section'>
      <img className='logo-img' src="asstes/img/logo.png" alt="" />
      <div className="login-block">
         <div className="title-text mb-3">
            <div className="title login">Setup Authenticator</div>
         </div>
         <div className="form-container">
            <div className='paremnt-block-qur'>
               <div className='section-left'>
                  <h3>Scan Code</h3>
                  <p>
                     Scan the QR code from the <br />
                     <span>Google Authenticator App</span>
                  </p>
                  <ul>
                     <li>
                        <p>In the app, tap on Menu and select "Set up account"</p>
                     </li>
                     <li>
                        <p>Select "Scan a barcode". Use your phone's camera to scan this barcode.</p>
                     </li>
                  </ul>
                  <a href='#'>Can't scan the barcode?</a>
               </div>
               <div className='section-right'>
                  <img src='asstes/img/quer.png' style={{width: "100%"}} />
               </div>
            </div>
            <div className="form-inner mt-3">
                <h3>Verify and Save</h3>              
                <p>Once you have scanned the barcode, enter the 6-digit verification code generated by the Authenticator app</p>
               <form action="#" className="login">
                  <div className='row'>
                  <div className="col-12 col-md-8">
                     <div className='field'>
                     <input type="number"  id="otp" placeholder="" required />
                     <input type="number" id="otp" placeholder="" required />
                     <input type="number" id="otp" placeholder="" required />
                     <input type="number" id="otp" placeholder="" required />
                     <input type="number" id="otp" placeholder="" required />
                     <input type="number" id="otp" placeholder="" required />
                     </div>
                  </div>
                  <div className='col-12 col-md-4'>
                  <button type="submit" className="btn btn-primary">Verify and Save</button>
                  </div>
                  </div>
               </form>
            </div>
         </div>
      </div>
   </div>
        </>
    )
}